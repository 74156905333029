import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./middleware/auth";
import Test from "../pages/Test/Test";
import SubscriptionForm from "../pages/SubscriptionForm/SubscriptionForm";
import Login from "../pages/Login/Login";
import Payment from "../pages/Payment/Payment";
import Abouts from "../pages/About/Abouts";
import PrivacyPolicy from "../pages/Policy/PrivacyPolicy";
import Contact from "../pages/Contact/Contact";
import HowItWorks from "../pages/HowItWorks/HowItWorks";
import Landing from "../pages/Landing/Landing";
import Pricing from "../pages/Pricing/Pricing";
import Home from "../pages/Dashboard/Home/Home";
import Profile from "../pages/Dashboard/Profile/Profile";
import AdminDashboard from "../pages/AdminDashboard/Home/AdminDashboard";
import CreateUser from "../pages/AdminDashboard/CreateUser/CreateUser";

// import Login from "../Pages/Auth/Login";
// import Splash from "../Pages/Auth/Splash";
// import { VendorRoutes } from "./VendorRoutes";
// import { AdminRoutes } from "./AdminRoutes";
// import SupervisorRoutes from "./SupervisorRoutes";
// import { ProviderRoutes } from "./ProviderRoutes";
// import EditProfile from "../Pages/Auth/EditProfile";
// import ForgotPassword from "../Pages/Auth/ForgotPassword";
// import ResetPassword from "../Pages/Auth/ResetPassword";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* auth router  */}
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/profile-form" element={<SubscriptionForm />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/about" element={<Abouts />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/how-it-works" element={<HowItWorks />} />
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route element={<PrivateRoute />}>
          <Route exact path="/payment" element={<Payment />} />
          <Route exact path="/dashboard/home" element={<Home />} />
          <Route exact path="/dashboard/profile" element={<Profile />} />
          <Route
            exact
            path="/admin-dashboard/home"
            element={<AdminDashboard />}
          />
          <Route
            exact
            path="/admin-dashboard/create-user"
            element={<CreateUser />}
          />
        </Route>
        {/* <Route exact path="/forgot-password" element={<ForgotPassword />} /> */}{" "}
        {/* <Route
          exact
          path={`/reset-password/:id/:token`}
          element={<ResetPassword />}
        /> */}
        {/* Vendor */}
        {/* <Route element={<PrivateRoute role="vendor" />}>{VendorRoutes()}</Route> */}
        {/* Admin */}
        {/* <Route element={<PrivateRoute role="admin" />}>{AdminRoutes()}</Route> */}
        {/* Supervisor */}
        {/* <Route element={<PrivateRoute role="supervisor" />}>
          {SupervisorRoutes()}
        </Route> */}
        {/* Provider */}
        {/* <Route element={<PrivateRoute role="doctor" />}>
          {ProviderRoutes()}
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
}
