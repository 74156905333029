import "./App.css";
import React, { useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import $ from "jquery";

import { Route, Routes } from "react-router-dom";
import Router from "./routes/Router.js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

function App() {
  const initialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    intent: "capture",
    currency: "USD",
    // disableFunding: "credit,card,venmo",
  };

  return (
    <React.Fragment>
      <PayPalScriptProvider options={initialOptions}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Router />
        </GoogleOAuthProvider>
      </PayPalScriptProvider>
    </React.Fragment>
  );
}

export default App;
