import React from "react";
import AboutDetail1 from "../../assets/images/resources/about detail-1.jpg";
import AboutThreeClient from "../../assets/images/resources/about-three-client-img.jpg";
import AboutThreeClientSignature from "../../assets/images/shapes/about-three-client-signature.png";
import WhyChooseFiveShape from "../../assets/images/shapes/why-choose-five-shape-1.png";
import WhyChooseFiveShape2 from "../../assets/images/shapes/why-choose-five-shape-2.png";
import AboutBanner from "../../assets/images/backgrounds/about-banner.jpg";
import WhyChooseFiveShape3 from "../../assets/images/shapes/why-choose-five-shape-3.png";
import SiteFooterFour from "../../assets/images/shapes/site-footer-four-shape-1.png";
import Feature1Shape1 from "../../assets/images/shapes/feature-one-shape-1.png";
import Feature1HoverShape2 from "../../assets/images/shapes/feature-one-hover-shape-2.png";
import Feature1HoverShape1 from "../../assets/images/shapes/feature-one-hover-shape-1.png";
import CTAThreeBgShape from "../../assets/images/shapes/cta-three-bg-shape.png";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import TalentSyncLogo from "../../assets/images/talentSync_Logo.png";

const PrivacyPolicy = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box
      sx={{ width: 250, backgroundColor: "#040b1e", color: "white" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <div
        style={{
          display: "flex ",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "fit-content",
        }}
      >
        <img src={TalentSyncLogo} style={{ width: "55%" }} alt="logo"></img>
        <span
          className="mobile-nav__close mobile-nav__toggler"
          style={{ marginTop: "5%" }}
        >
          <i className="fa fa-times" onClick={toggleDrawer(false)}></i>
        </span>
      </div>
      <List>
        {/* Navigation Items */}
        <ListItem button component="a" href="/">
          <ListItemText
            primary="Home"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/about">
          <ListItemText
            primary="About"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/pricing">
          <ListItemText
            primary="Pricing"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/how-it-works">
          <ListItemText
            primary="How It Works"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/contact">
          <ListItemText
            primary="Contact"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
      </List>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
          marginTop: "8%",
        }}
      >
        <a href="#" className="fab fa-twitter" style={{ color: "white" }}></a>
        <a
          href="#"
          className="fab fa-facebook-square"
          style={{ color: "white" }}
        ></a>
        <a
          href="#"
          className="fab fa-pinterest-p"
          style={{ color: "white" }}
        ></a>
        <a href="#" className="fab fa-instagram" style={{ color: "white" }}></a>
      </div>
    </Box>
  );
  return (
    <div>
      <div className="page-wrapper">
        <div className="page-wrapper">
          <header className="main-header-four">
            <div className="main-header-four__top">
              <div className="container">
                <div className="main-header-four__top-inner">
                  <div className="main-header-four__top-left">
                    <div className="main-header-four__logo">
                      {/* <!-- <a href="/"><img src="assets/images/resources/talentsync-logo.png" alt=""></a> --> */}
                      <a href="/">
                        <img
                          src={TalentSyncLogo}
                          style={{ width: "20%" }}
                        ></img>
                      </a>
                    </div>
                    {/* <!-- <div className="main-header-four__search-box">
                                <form action="#" className="main-header-four__search-form">
                                    <input type="search" placeholder="Search....">
                                    <button type="submit"><i className="icon-search"></i></button>
                                </form>
                            </div> --> */}
                  </div>
                  <ul className="list-unstyled main-header-four__contact-list">
                    <li>
                      <div className="text">
                        <span>email</span>
                        <p>
                          <a href="mailto:sonchoy@gmail.com">
                            info@talentsync.com
                          </a>
                        </p>
                      </div>
                    </li>
                    {/* <!-- <li>
                                <div className="text">
                                    <span>PHONE</span>
                                    <p><a href="tel:18000000000">1800 000 0000 </a></p>
                                </div>
                            </li> --> */}
                  </ul>
                </div>
              </div>
            </div>
            <nav className="main-menu main-menu-four">
              <div className="main-menu-four__wrapper">
                <div className="container">
                  <div className="main-menu-four__wrapper-inner">
                    <div className="main-menu-four__left">
                      <div className="main-menu-four__main-menu-box">
                        <a className="mobile-nav__toggler">
                          <i
                            className="fa fa-bars"
                            onClick={toggleDrawer(true)}
                          ></i>
                        </a>
                        <Drawer
                          sx={{
                            "& .MuiDrawer-paper": {
                              backgroundColor: "#040b1e",
                              px: "15px",
                              py: "30px",
                            },
                          }}
                          open={open}
                          onClose={toggleDrawer(false)}
                        >
                          {DrawerList}
                        </Drawer>
                        <ul className="main-menu__list">
                          <li className="dropdown">
                            <a href="/">Home </a>
                          </li>
                          <li>
                            <a href="/about">About</a>
                          </li>

                          <li>
                            <a href="/pricing">Pricing</a>
                          </li>

                          <li>
                            <a href="/how-it-works">How It Works</a>
                          </li>
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="main-menu-four__right">
                      <div className="main-menu-four__cart">
                        <a href="#">
                          <span className="icon-cart"></span>
                        </a>
                      </div>
                      {/* <div className="main-menu-four__btn-box">
                      <a
                        href="/contact"
                        className="main-menu-four__btn thm-btn"
                      >
                        GET A QUOTE
                      </a>
                    </div> */}
                      <div
                        className="main-menu-four__btn-box"
                        style={{ marginLeft: "10px" }}
                      >
                        <a
                          href="/login"
                          className="main-menu-four__btn thm-btn"
                        >
                          Log In
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>

          {/* <!--Content Start--> */}

          
          <>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <title>1cf5de67-82ce-417a-aa69-4f68b312b395</title>
            <meta name="author" content="Ali Mahdi" />
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html:
                  " * {margin:0; padding:0; text-indent:0; }\n p { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; margin:0pt; }\n .a, a { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }\n"
              }}
            />
            <p
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              Privacy Policy for TanlentSync
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Privacy Policy
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              Last updated: January 12, 2025
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              This Privacy Policy describes Our policies and procedures on the collection,
              use and
            </p>
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              disclosure of Your information when You use the Service and tells You about
              Your privacy rights and how the law protects You.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              We use Your Personal data to provide and improve the Service. By using the
              Service, You agree to the collection and use of information in accordance
              with this Privacy Policy. This Privacy Policy has been created with the help
              of the Privacy Policy Generator.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "170%",
                textAlign: "left"
              }}
            >
              Interpretation and Definitions Interpretation
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              The words of which the initial letter is capitalized have meanings defined
              under the
            </p>
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              following conditions. The following definitions shall have the same meaning
              regardless of whether they appear in singular or in plural.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Definitions
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              For the purposes of this Privacy Policy:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "117%",
                textAlign: "left"
              }}
            >
              Account means a unique account created for You to access our Service or
              parts of our Service.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Affiliate means an entity that controls, is controlled by or is under common
              control with a party, where "control" means ownership of 50% or more of the
              shares, equity interest or other securities entitled to vote for election of
              directors or other managing authority.
            </p>
            <p
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Company (referred to as either "the Company", "We", "Us" or "Our" in this
              Agreement) refers to TanlentSync.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Cookies are small files that are placed on Your computer, mobile device or
              any other
            </p>
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              device by a website, containing the details of Your browsing history on that
              website among its many uses.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Country refers to: Bahrain
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Device means any device that can access the Service such as a computer, a
              cellphone or a digital tablet.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Personal Data is any information that relates to an identified or
              identifiable individual.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Service refers to the Website.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Service Provider means any natural or legal person who processes the data on
              behalf of the Company. It refers to third-party companies or individuals
              employed by the Company to
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              facilitate the Service, to provide the Service on behalf of the Company, to
              perform services related to the Service or to assist the Company in
              analyzing how the Service is used.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Third-party Social Media Service refers to any website or any social network
              website through which a User can log in or create an account to use the
              Service.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Usage Data refers to data collected automatically, either generated by the
              use of the Service or from the Service infrastructure itself (for example,
              the duration of a page visit).
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Website refers to TanlentSync, accessible from https://talentsync.agency
            </p>
            <p
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              You means the individual accessing or using the Service, or the company, or
              other legal entity on behalf of which such individual is accessing or using
              the Service, as applicable.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "170%",
                textAlign: "left"
              }}
            >
              Collecting and Using Your Personal Data Types of Data Collected
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Personal Data
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "justify"
              }}
            >
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or identify
              You. Personally identifiable information may include, but is not limited to:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Email address
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              First name and last name
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Phone number
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Address, State, Province, ZIP/Postal code, City
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Access and Refresh Tokens
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              Collection of Tokens: When you authenticate using Google OAuth 2.0, we
              collect your
            </p>
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              access and refresh tokens. These tokens enable us to interact with the Gmail
              API on your behalf to provide you with the intended service functionality.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Use of Tokens:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "justify"
              }}
            >
              The access token is used to access your Gmail data, including sending and
              reading emails, retrieving email metadata, and viewing replies.
            </p>
            <p
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              The refresh token is used to obtain a new access token when the current one
              expires, ensuring uninterrupted access to the Gmail API.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Storage of Tokens:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Access and refresh tokens are stored securely in our database using
              industry-standard encryption methods to protect their confidentiality and
              integrity.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Data Security:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              We implement robust security measures to protect your tokens from
              unauthorized access, disclosure, alteration, or destruction.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Our systems adhere to best practices and industry standards for data
              protection and security.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              User Control:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              You have the right to revoke access to your Google account at any time
              through your Google account settings or by contacting our support team.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Upon revocation, we will promptly delete your access and refresh tokens from
              our database.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Data Sharing:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left"
              }}
            >
              We do not share your access or refresh tokens with any third parties, except
              as required by law or to protect our rights and ensure the security of our
              systems.
            </p>
            <p
              style={{
                paddingTop: "5pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "justify"
              }}
            >
              Changes to This Policy:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "justify"
              }}
            >
              Any changes to our handling of access and refresh tokens will be
              communicated through updates to this privacy policy. We encourage you to
              review this policy periodically for any changes.
            </p>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "justify"
              }}
            >
              Usage Data
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
              Usage Data
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "justify"
              }}
            >
              Usage Data is collected automatically when using the Service.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "justify"
              }}
            >
              Usage Data may include information such as Your Device's Internet Protocol
              address (e.g. IP address), browser type, browser version, the pages of our
              Service that You visit, the time and date of Your visit, the time spent on
              those pages, unique device identifiers and other
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
              diagnostic data.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              When You access the Service by or through a mobile device, We may collect
              certain information automatically, including, but not limited to, the type
              of mobile device You use, Your mobile device unique ID, the IP address of
              Your mobile device, Your mobile operating system, the type of mobile
              Internet browser You use, unique device identifiers and other
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "15pt",
                textAlign: "left"
              }}
            >
              diagnostic data.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              We may also collect information that Your browser sends whenever You visit
              our Service or when You access the Service by or through a mobile device.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
              Information from Third-Party Social Media Services
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              The Company allows You to create an account and log in to use the Service
              through the following Third-party Social Media Services:
            </p>
            <p
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              Google
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              If You decide to register through or otherwise grant us access to a
              Third-Party Social Media Service, We may collect Personal data that is
              already associated with Your Third-Party
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Social Media Service's account, such as Your name, Your email address, Your
              activities or Your contact list associated with that account.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              You may also have the option of sharing additional information with the
              Company through Your Third-Party Social Media Service's account. If You
              choose to provide such information and Personal Data, during registration or
              otherwise, You are giving the Company permission to use, share, and store it
              in a manner consistent with this Privacy Policy.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Use of Your Personal Data
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              To manage Your Account: to manage Your registration as a user of the
              Service. The Personal Data You provide can give You access to different
              functionalities of the Service that are
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "15pt",
                textAlign: "left"
              }}
            >
              available to You as a registered user.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "justify"
              }}
            >
              For the performance of a contract: the development, compliance and
              undertaking of the purchase contract for the products, items or services You
              have purchased or of any other contract with Us through the Service.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              To contact You: To contact You by email, telephone calls, SMS, or other
              equivalent forms of electronic communication, such as a mobile application's
              push notifications regarding updates or informative communications related
              to the functionalities, products or
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              contracted services, including the security updates, when necessary or
              reasonable for their implementation.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              To provide You with news, special offers and general information about other
              goods, services and events which we offer that are similar to those that you
              have already purchased or enquired about unless You have opted not to
              receive such information.
            </p>
            <p
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              To manage Your requests: To attend and manage Your requests to Us.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              For business transfers: We may use Your information to evaluate or conduct a
              merger, divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of Our assets, whether as a going concern or
              as part of bankruptcy, liquidation, or similar proceeding, in which Personal
              Data held by Us about our Service users is among the assets transferred.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              For other purposes: We may use Your information for other purposes, such as
              data analysis, identifying usage trends, determining the effectiveness of
              our promotional campaigns and to evaluate and improve our Service, products,
              services, marketing and your experience.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              We may share Your personal information in the following situations:
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              With Service Providers: We may share Your personal information with Service
              Providers to monitor and analyze the use of our Service, to contact You.
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              For business transfers: We may share or transfer Your personal information
              in connection with, or during negotiations of, any merger, sale of Company
              assets, financing, or
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "15pt",
                textAlign: "left"
              }}
            >
              acquisition of all or a portion of Our business to another company.
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              With Affiliates: We may share Your information with Our affiliates, in which
              case we will require those affiliates to honor this Privacy Policy.
              Affiliates include Our parent company and any other subsidiaries, joint
              venture partners or other companies that We control or that are under common
              control with Us.
            </p>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              With business partners: We may share Your information with Our business
              partners to offer You certain products, services or promotions.
            </p>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              With other users: when You share personal information or otherwise interact
              in the public areas with other users, such information may be viewed by all
              users and may be publicly distributed outside. If You interact with other
              users or register through a Third-Party Social Media Service, Your contacts
              on the Third-Party Social Media Service may see Your name, profile, pictures
              and description of Your activity. Similarly, other users will be able to
              view descriptions of Your activity, communicate with You and view Your
              profile.
            </p>
            <p
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              With Your consent: We may disclose Your personal information for any other
              purpose with Your consent.
            </p>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              Retention of Your Personal Data
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              The Company will retain Your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain and use Your
              Personal Data to the extent necessary to comply with our legal obligations
              (for example, if we are required to
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              retain your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              The Company will also retain Usage Data for internal analysis purposes.
              Usage Data is
            </p>
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              generally retained for a shorter period of time, except when this data is
              used to strengthen the security or to improve the functionality of Our
              Service, or We are legally obligated to retain this data for longer time
              periods.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Transfer of Your Personal Data
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Your information, including Personal Data, is processed at the Company's
              operating offices and in any other places where the parties involved in the
              processing are located. It means that this information may be transferred to
              — and maintained on — computers located outside of Your state, province,
              country or other governmental jurisdiction where the data protection laws
              may differ than those from Your jurisdiction.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Your consent to this Privacy Policy followed by Your submission of such
              information represents Your agreement to that transfer.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "justify"
              }}
            >
              The Company will take all steps reasonably necessary to ensure that Your
              data is treated securely and in accordance with this Privacy Policy and no
              transfer of Your Personal Data will take place to an organization or a
              country unless there are adequate controls in place including the security
              of Your data and other personal information.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Delete Your Personal Data
            </p>
            <p
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              You have the right to delete or request that We assist in deleting the
              Personal Data that We have collected about You.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Our Service may give You the ability to delete certain information about You
              from within the Service.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              You may update, amend, or delete Your information at any time by signing in
              to Your Account, if you have one, and visiting the account settings section
              that allows you to
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              manage Your personal information. You may also contact Us to request access
              to, correct, or delete any personal information that You have provided to
              Us.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Please note, however, that We may need to retain certain information when we
              have a legal obligation or lawful basis to do so.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "170%",
                textAlign: "left"
              }}
            >
              Disclosure of Your Personal Data Business Transactions
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              If the Company is involved in a merger, acquisition or asset sale, Your
              Personal Data may be transferred. We will provide notice before Your
              Personal Data is transferred and becomes subject to a different Privacy
              Policy.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Law enforcement
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Under certain circumstances, the Company may be required to disclose Your
              Personal Data if required to do so by law or in response to valid requests
              by public authorities (e.g. a court or a government agency).
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Other legal requirements
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              The Company may disclose Your Personal Data in the good faith belief that
              such action is necessary to:
            </p>
            <p
              style={{
                paddingTop: "3pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              Comply with a legal obligation
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              Protect and defend the rights or property of the Company
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "170%",
                textAlign: "left"
              }}
            >
              Prevent or investigate possible wrongdoing in connection with the Service
              Protect the personal safety of Users of the Service or the public
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "170%",
                textAlign: "left"
              }}
            >
              Protect against legal liability Security of Your Personal Data
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              The security of Your Personal Data is important to Us, but remember that no
              method of transmission over the Internet, or method of electronic storage is
              100% secure. While We strive to use commercially acceptable means to protect
              Your Personal Data, We cannot guarantee its absolute security.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Children's Privacy
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Our Service does not address anyone under the age of 13. We do not knowingly
              collect personally identifiable information from anyone under the age of 13.
              If You are a parent or guardian and You are aware that Your child has
              provided Us with Personal Data, please contact Us. If We become aware that
              We have collected Personal Data from anyone under the age of 13 without
              verification of parental consent, We take steps to remove that information
              from Our servers.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We may require
              Your parent's consent before We collect and use that information.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Links to Other Websites
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              Our Service may contain links to other websites that are not operated by Us.
              If You click on a third party link, You will be directed to that third
              party's site. We strongly advise You to
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "15pt",
                textAlign: "left"
              }}
            >
              review the Privacy Policy of every site You visit.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "114%",
                textAlign: "left"
              }}
            >
              We have no control over and assume no responsibility for the content,
              privacy policies or practices of any third party sites or services.
            </p>
            <p
              style={{
                paddingTop: "5pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left"
              }}
            >
              Changes to this Privacy Policy
            </p>
            <p
              style={{
                paddingTop: "10pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              We may update Our Privacy Policy from time to time. We will notify You of
              any changes by posting the new Privacy Policy on this page.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              We will let You know via email and/or a prominent notice on Our Service,
              prior to the change becoming effective and update the "Last updated" date at
              the top of this Privacy Policy.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "115%",
                textAlign: "left"
              }}
            >
              You are advised to review this Privacy Policy periodically for any changes.
              Changes to this Privacy Policy are effective when they are posted on this
              page.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
              <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
              Contact Us
            </p>
            
            <p style={{ paddingTop: "10pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                If you have any questions about this Privacy Policy, You can contact us: career@talentsync.agency
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                <a href="/Privacy_Policy.pdf" download>Download Privacy Policy as PDF</a>
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                <a href="mailto:career@talentsync.agency" className="a" target="_blank">
                    By email: 
                </a>
                <a href="mailto:career@talentsync.agency" target="_blank">
                    career@talentsync.agency
                </a>
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                <a href="/Privacy_Policy.pdf" download>Download Privacy Policy as PDF</a>
            </p>


          </>

          {/* <!--Site Footer Start--> */}
          <footer className="site-footer site-footer-three site-footer-four">
            <div className="site-footer__shape-1 float-bob-y">
              <img src={SiteFooterFour} alt="" />
            </div>
            <div className="site-footer__top">
              <div className="container">
                <div className="site-footer__top-inner">
                  <div className="row">
                    {/* About Section */}
                    <div
                      className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                      data-wow-delay="100ms"
                    >
                      <div className="footer-widget__column footer-widget__about">
                        <div className="footer-widget__logo">
                          <a href="/">
                            <h2 style={{ color: "white" }}>Talentsync</h2>
                          </a>
                        </div>
                        <p className="footer-widget__about-text">
                          Get Noticed by Employers: TalentSync Finds You the
                          Perfect Job
                        </p>
                        <div className="footer-widget__emergency-call">
                          <a href="mailto:info@talentsync.com">
                            info@talentsync.com
                          </a>
                        </div>
                        <div className="footer-widget__social">
                          <a href="#">
                            <span className="icon-facebook"></span>
                          </a>
                          <a href="#">
                            <span className="icon-instagram-1"></span>
                          </a>
                          <a href="#">
                            <span className="icon-tik-tok"></span>
                          </a>
                          <a href="#">
                            <span className="icon-youtube"></span>
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* Navigation Section */}
                    <div
                      className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                      data-wow-delay="200ms"
                    >
                      <div className="footer-widget__column footer-widget__navigation">
                        <div className="footer-widget__title-box">
                          <h3 className="footer-widget__title">Navigation</h3>
                        </div>
                        <ul className="footer-widget__navigation-list list-unstyled">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="/about">About Us</a>
                          </li>
                          <li>
                            <a href="/how-it-works">How It Works</a>
                          </li>
                          <li>
                            <a href="/pricing">Pricing</a>
                          </li>
                          <li>
                            <a href="/contact">Contact Us</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer Bottom */}
            <div className="site-footer__bottom">
              <div className="container">
                <div className="site-footer__bottom-inner">
                  <p className="site-footer__bottom-text">
                    Copyright © 2024 Talentsync.Agency. All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
