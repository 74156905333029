import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SnackbarComponent from "../../components/Snackbar/Snackbar";
import SiteFooterFourShape1 from "../../assets/images/shapes/site-footer-four-shape-1.png";
import Logo2 from "../../assets/images/resources/logo-2.png";
import AboutDetail3 from "../../assets/images/resources/about-detail3.jpg";
import CTAThreeBgShape from "../../assets/images/shapes/cta-three-bg-shape.png";
import { backendUrl } from "../../constants/config";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import TalentSyncLogo from "../../assets/images/talentSync_Logo.png";

const SubscriptionForm = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box
      sx={{ width: 250, backgroundColor: "#040b1e", color: "white" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <div
        style={{
          display: "flex ",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "fit-content",
        }}
      >
        <img src={TalentSyncLogo} style={{ width: "55%" }} alt="logo"></img>
        <span
          className="mobile-nav__close mobile-nav__toggler"
          style={{ marginTop: "5%" }}
        >
          <i className="fa fa-times" onClick={toggleDrawer(false)}></i>
        </span>
      </div>
      <List>
        {/* Navigation Items */}
        <ListItem button component="a" href="/">
          <ListItemText
            primary="Home"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/about">
          <ListItemText
            primary="About"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/pricing">
          <ListItemText
            primary="Pricing"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/how-it-works">
          <ListItemText
            primary="How It Works"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/contact">
          <ListItemText
            primary="Contact"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
      </List>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
          marginTop: "8%",
        }}
      >
        <a href="#" className="fab fa-twitter" style={{ color: "white" }}></a>
        <a
          href="#"
          className="fab fa-facebook-square"
          style={{ color: "white" }}
        ></a>
        <a
          href="#"
          className="fab fa-pinterest-p"
          style={{ color: "white" }}
        ></a>
        <a href="#" className="fab fa-instagram" style={{ color: "white" }}></a>
      </div>
    </Box>
  );
  const navigate = useNavigate();
  const [username, setUsername] = useState(""); // New state for username
  const [user, setUser] = useState(""); // New state for username
  const [userEmail, setUserEmail] = useState(""); // New state for username
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    email: "",
    referralSource: "",
    nationality: "",
    residency: "",
    jobTitles: "",
    experience: "",
    contact: "",
    avatar: null,
    avoidCompanies: [],
    education: "",
    educationCert: null, // Education certificate file
    professionalCert: null, // Professional certificate file
    industries: [],
    targetCompanySize: "",
    country: "",
    state: "",
    city: "",
    resume: null,
    coverLetter: "",
    additionalInfo: "",
  });

  const handleSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const industries = [
    "Accounting",
    "Agriculture",
    "Airlines/Aviation",
    "Alternative Dispute Resolution",
    "Alternative Medicine",
    "Animation",
    "Apparel & Fashion",
    "Architecture & Planning",
    "Arts & Crafts",
    "Automotive",
    "Aviation & Aerospace",
    "Banking",
    "Biotechnology",
    "Broadcast Media",
    "Building Materials",
    "Business Supplies & Equipment",
    "Capital Markets",
    "Chemicals",
    "Civic & Social Organization",
    "Civil Engineering",
    "Commercial Real Estate",
    "Computer & Network Security",
    "Computer Games",
    "Computer Hardware",
    "Computer Networking",
    "Computer Software",
    "Construction",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Dairy",
    "Defense & Space",
    "Design",
    "E-Learning",
    "Education Management",
    "Electrical/Electronic Manufacturing",
    "Entertainment",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Farming",
    "Financial Services",
    "Fine Art",
    "Fishery",
    "Food & Beverages",
    "Food Production",
    "Fund-Raising",
    "Furniture",
    "Gambling & Casinos",
    "Glass, Ceramics & Concrete",
    "Government Administration",
    "Government Relations",
    "Graphic Design",
    "Health, Wellness & Fitness",
    "Higher Education",
    "Hospital & Health Care",
    "Hospitality",
    "Human Resources",
    "Import & Export",
    "Individual & Family Services",
    "Industrial Automation",
    "Information Services",
    "Information Technology & Services",
    "Insurance",
    "International Affairs",
    "International Trade & Development",
    "Internet",
    "Investment Banking",
    "Investment Management",
    "Judiciary",
    "Law Enforcement",
    "Law Practice",
    "Legal Services",
    "Legislative Office",
    "Leisure, Travel & Tourism",
    "Libraries",
    "Logistics & Supply Chain",
    "Luxury Goods & Jewelry",
    "Machinery",
    "Management Consulting",
    "Maritime",
    "Market Research",
    "Marketing & Advertising",
    "Mechanical or Industrial Engineering",
    "Media Production",
    "Medical Devices",
    "Medical Practice",
    "Mental Health Care",
    "Military",
    "Mining & Metals",
    "Motion Pictures & Film",
    "Museums & Institutions",
    "Music",
    "Nanotechnology",
    "Newspapers",
    "Nonprofit Organization Management",
    "Oil & Energy",
    "Online Media",
    "Outsourcing/Offshoring",
    "Package/Freight Delivery",
    "Packaging & Containers",
    "Paper & Forest Products",
    "Performing Arts",
    "Pharmaceuticals",
    "Philanthropy",
    "Photography",
    "Plastics",
    "Political Organization",
    "Primary/Secondary Education",
    "Printing",
    "Professional Training & Coaching",
    "Program Development",
    "Public Policy",
    "Public Relations & Communications",
    "Public Safety",
    "Publishing",
    "Railroad Manufacture",
    "Ranching",
    "Real Estate",
    "Recreational Facilities & Services",
    "Religious Institutions",
    "Renewables & Environment",
    "Research",
    "Restaurants",
    "Retail",
    "Security & Investigations",
    "Semiconductors",
    "Shipbuilding",
    "Sporting Goods",
    "Sports",
    "Staffing & Recruiting",
    "Supermarkets",
    "Telecommunications",
    "Textiles",
    "Think Tanks",
    "Tobacco",
    "Translation & Localization",
    "Transportation/Trucking/Railroad",
    "Utilities",
    "Venture Capital & Private Equity",
    "Veterinary",
    "Warehousing",
    "Wholesale",
    "Wine & Spirits",
    "Wireless",
    "Writing & Editing",
  ];

  const industryOptions = [
    { value: "", label: "Select Industry" },
    ...industries.map((industry) => ({ value: industry, label: industry })),
  ];
  const educationLevelOptions = [
    { value: "", label: "Select Education Level" },
    { value: "Secondary School", label: "Secondary School" },
    {
      value: "Certificate/Diploma Programs",
      label: "Certificate/Diploma Programs",
    },
    { value: "Associate's Degree", label: "Associate's Degree" },
    { value: "Bachelor's Degree", label: "Bachelor's Degree" },
    { value: "Master's Degree", label: "Master's Degree" },
    { value: "Doctorate/Ph.D.", label: "Doctorate/Ph.D." },
  ];

  const companySizeOptions = [
    { value: "", label: "Select Company Size" },
    {
      value: "Small (less than 50 employees)",
      label: "Small (less than 50 employees)",
    },
    { value: "Mid (50-500 employees)", label: "Mid (50-500 employees)" },
    {
      value: "Large (500-5000 employees)",
      label: "Large (500-5000 employees)",
    },
    {
      value: "International (5000+ employees)",
      label: "International (5000+ employees)",
    },
  ];

  const [avoidCompany, setAvoidCompany] = useState("");
  const addAvoidCompany = () => {
    if (avoidCompany !== "") {
      setFormData((prevState) => ({
        ...prevState,
        avoidCompanies: [...(prevState.avoidCompanies || []), avoidCompany], // Use prevState for up-to-date state
      }));

      setAvoidCompany("");
    }
  };

  const removeAvoidCompany = (name) => {
    setFormData((prevState) => ({
      ...prevState,
      avoidCompanies: [...formData.avoidCompanies?.filter((i) => i !== name)],
    }));
    console.log(
      name,
      formData.avoidCompanies?.filter((i) => i !== name)
    );
  };

  useEffect(() => {
    const talentSyncUser = JSON.parse(localStorage.getItem("TalentSyncUser"));
    if (talentSyncUser && talentSyncUser.name) {
      setUsername(talentSyncUser.name);
      setUserEmail(talentSyncUser.email);
      setFormData((prevState) => ({
        ...prevState,
        name: talentSyncUser.name,
        email: talentSyncUser.email,
      }));
      setUser(talentSyncUser);
    }
    setStates([{ value: "", label: "Select State" }]);
    setCities([{ value: "", label: "Select City" }]);
  }, []);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (formData.country) {
      const selectedCountry = countries.find(
        (country) => country.value === formData.country
      );
      if (selectedCountry) fetchStates(selectedCountry.code);
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData.state) {
      const selectedCountry = countries.find(
        (country) => country.value === formData.country
      );
      const selectedState = states.find(
        (state) => state.value === formData.state
      );
      if (selectedCountry && selectedState)
        fetchCities(selectedCountry.code, selectedState.code);
    }
  }, [formData.state]);

  const fetchCountries = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.countrystatecity.in/v1/countries",
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      const filteredCountries = response.data.map((country) => ({
        label: country.name,
        value: country.name,
        code: country.iso2,
      }));

      setCountries([
        { label: "Select Country", value: "" },
        ...filteredCountries,
      ]);
      setLoading(false);
    } catch (error) {
      setErr("Error fetching countries.");
      setLoading(false);
    }
  };

  const fetchStates = async (code) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${code}/states`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      setStates([
        { label: "Select State", value: "" },
        ...response.data.map((state) => ({
          label: state.name,
          value: state.name,
          code: state.iso2,
        })),
      ]);
      setLoading(false);
    } catch (error) {
      setErr("Error fetching states.");
      setLoading(false);
    }
  };

  const fetchCities = async (countryCode, stateCode) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateCode}/cities`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      setCities([
        { label: "Select City", value: "" },
        ...response.data.map((city) => ({
          label: city.name,
          value: city.name,
        })),
      ]);
      setLoading(false);
    } catch (error) {
      setErr("Error fetching cities.");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    // Reset dependent fields
    if (name === "country") {
      setFormData((prevState) => ({ ...prevState, state: "", city: "" }));
    } else if (name === "state") {
      setFormData((prevState) => ({ ...prevState, city: "" }));
    }
  };

  const handleFileUpload = async (file, type) => {
    if (!file) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${backendUrl}/aws/file?email=TalentSync`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      // Update the corresponding field in formData
      setFormData((prevState) => ({
        ...prevState,
        [type]: response.data.url,
      }));

      setSuccess(`${type} uploaded successfully!`);
    } catch (err) {
      console.error("Error uploading file:", err);
      setErr("Error uploading file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const [selectedIndustry, setSelectedIndustry] = useState("");

  const handleIndustryChange = (e) => {
    const value = e.target.value;

    // Add to industries array if it's not already there
    if (value && !formData.industries.includes(value)) {
      setFormData({
        ...formData,
        industries: [...formData.industries, value],
      });
    }
    setSelectedIndustry(""); // Reset dropdown
  };

  const removeIndustry = (industry) => {
    setFormData({
      ...formData,
      industries: formData.industries.filter((item) => item !== industry),
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      handleFileUpload(files[0], name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = { ...formData };

    try {
      const response = await axios.put(`${backendUrl}/user/updateUser`, {
        id: user.id,
        user: formDataObj,
      });

      setSuccess("Form Submitted Successfully.");
      localStorage.setItem(
        "TalentSyncUser",
        JSON.stringify(response.data.updatedUser)
      );
      setTimeout(() => {
        navigate("/dashboard/home");
      }, 2000);
    } catch (err) {
      console.log(err);
      setErr("Error submitting form.");
    }
  };

  return (
    <div className="page-wrapper">
      <SnackbarComponent
        err={err}
        success={success}
        setErr={setErr}
        setSuccess={setSuccess}
      />
      <div>
        <div className="page-wrapper">
          <header className="main-header-four">
            <div className="main-header-four__top">
              <div className="container">
                <div className="main-header-four__top-inner">
                  <div className="main-header-four__top-left">
                    <div className="main-header-four__logo">
                      {/* <!-- <a href="/"><img src="assets/images/resources/talentsync-logo.png" alt=""></a> --> */}
                      <a href="/">
                        <img
                          src={TalentSyncLogo}
                          style={{ width: "20%" }}
                        ></img>
                      </a>
                    </div>
                    {/* <!-- <div className="main-header-four__search-box">
                                <form action="#" className="main-header-four__search-form">
                                    <input type="search" placeholder="Search....">
                                    <button type="submit"><i className="icon-search"></i></button>
                                </form>
                            </div> --> */}
                  </div>
                  <ul className="list-unstyled main-header-four__contact-list">
                    <li>
                      <div className="text">
                        <span>email</span>
                        <p>
                          <a href="mailto:sonchoy@gmail.com">
                            info@talentsync.com
                          </a>
                        </p>
                      </div>
                    </li>
                    {/* <!-- <li>
                                <div className="text">
                                    <span>PHONE</span>
                                    <p><a href="tel:18000000000">1800 000 0000 </a></p>
                                </div>
                            </li> --> */}
                  </ul>
                </div>
              </div>
            </div>
            <nav className="main-menu main-menu-four">
              <div className="main-menu-four__wrapper">
                <div className="container">
                  <div className="main-menu-four__wrapper-inner">
                    <div className="main-menu-four__left">
                      <div className="main-menu-four__main-menu-box">
                        <a className="mobile-nav__toggler">
                          <i
                            className="fa fa-bars"
                            onClick={toggleDrawer(true)}
                          ></i>
                        </a>
                        <Drawer
                          sx={{
                            "& .MuiDrawer-paper": {
                              backgroundColor: "#040b1e",
                              px: "15px",
                              py: "30px",
                            },
                          }}
                          open={open}
                          onClose={toggleDrawer(false)}
                        >
                          {DrawerList}
                        </Drawer>
                        <ul className="main-menu__list">
                          <li className="dropdown">
                            <a href="/">Home </a>
                          </li>
                          <li>
                            <a href="/about">About</a>
                          </li>

                          <li>
                            <a href="/pricing">Pricing</a>
                          </li>

                          <li>
                            <a href="/how-it-works">How It Works</a>
                          </li>
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="main-menu-four__right">
                      <div className="main-menu-four__cart">
                        <a href="#">
                          <span className="icon-cart"></span>
                        </a>
                      </div>
                      {/* <div className="main-menu-four__btn-box">
                      <a
                        href="/contact"
                        className="main-menu-four__btn thm-btn"
                      >
                        GET A QUOTE
                      </a>
                    </div> */}
                      {/* <div
                      className="main-menu-four__btn-box"
                      style={{ marginLeft: "10px" }}
                    >
                      <a href="/login" className="main-menu-four__btn thm-btn">
                        Log In
                      </a>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </header>

          <div className="stricky-header stricked-menu main-menu main-menu-four">
            <div className="sticky-header__content"></div>
          </div>

          {/* <!--Page Header Start--> */}
          <section className="page-header">
            <div
              className="page-header__bg"
              style={{
                backgroundImage: `url(${AboutDetail3})`,
              }}
            ></div>
            <div className="container">
              <div className="page-header__inner">
                <h2>Profile</h2>
                <div className="thm-breadcrumb__box">
                  <ul className="thm-breadcrumb list-unstyled">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <span>-</span>
                    </li>
                    <li>Profile</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          {/* <!--Page Header End--> */}

          {/* <!--Contact Page Start--> */}
          <section className="contact-page">
            <div className="container">
              <div className="contact-page__bottom">
                <div className="comment-form">
                  <h3 className="comment-one__title">Welcome, {username}!</h3>
                  <p>To start you need to initiate your profile.</p>
                  <Form
                    className="comment-one__form contact-form-validated"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <input
                            type="number"
                            placeholder="Your Age"
                            name="age"
                            id="age"
                            value={formData.age}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <input
                            placeholder="Your Email"
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <input
                            type="text"
                            name="contact"
                            id="contact"
                            placeholder="Contact no with country code eg: '(+12) 123456789'"
                            value={formData.contact}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <input
                            type="text"
                            name="nationality"
                            id="nationality"
                            placeholder="Nationality"
                            value={formData.nationality}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <input
                            type="text"
                            name="residency"
                            id="residency"
                            placeholder="Residency"
                            value={formData.residency}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <select
                                name="referralSource"
                                id="referralSource"
                                value={formData.referralSource}
                                onChange={handleChange}
                                
                              >
                                <option default value="">
                                  {" "}
                                  How did you hear about us?
                                </option>
                                <option value="LinkedIn">LinkedIn</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Website">Website</option>
                                <option value="Google Ad">Google Ad</option>
                                <option value="Ad">Ad</option>
                                <option value="Directly from salesperson">
                                  Directly from salesperson
                                </option>
                              </select>
                            </div>
                          </div>
                        </div> */}
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <select
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                          >
                            {countries.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <select
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                          >
                            {states.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <select
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                          >
                            {cities.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <select
                            name="referralSource"
                            id="referralSource"
                            value={formData.referralSource}
                            onChange={handleChange}
                          >
                            <option default value="">
                              {" "}
                              How did you hear about us?
                            </option>
                            <option value="LinkedIn">LinkedIn</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Website">Website</option>
                            <option value="Google Ad">Google Ad</option>
                            <option value="Ad">Ad</option>
                            <option value="Directly from salesperson">
                              Directly from salesperson
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <input
                            type="text"
                            name="jobTitles"
                            id="jobTitles"
                            placeholder="Job Titles"
                            value={formData.jobTitles}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <input
                            type="number"
                            name="experience"
                            id="experience"
                            placeholder="Year of experience"
                            value={formData.experience}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <select
                            name="education"
                            value={formData.education}
                            onChange={(e) =>
                              handleSelectChange("education", e.target.value)
                            }
                          >
                            {educationLevelOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <select
                            name="targetCompanySize"
                            value={formData.targetCompanySize} // Use the actual string value
                            onChange={(e) =>
                              handleSelectChange(
                                "targetCompanySize",
                                e.target.value
                              )
                            } // Use e.target.value
                          >
                            {companySizeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <select
                            value={selectedIndustry}
                            onChange={handleIndustryChange}
                          >
                            <option value="">Select Industry</option>
                            {industryOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-xl-5 col-lg-5">
                        <div className="comment-form__input-box">
                          <input
                            type="text"
                            name="avoidCompanies"
                            id="avoidCompanies"
                            placeholder="Companies to avoid reaching out"
                            onChange={(e) => {
                              setAvoidCompany(e.target.value);
                            }}
                            value={avoidCompany}
                          />
                        </div>
                      </div>
                      <div className="col-xl-1 col-lg-1">
                        <button
                          onClick={addAvoidCompany}
                          type="button"
                          style={{
                            borderRadius: "10px",
                            border: "1px solid #1a73e9",
                            padding: "5px 10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      {/* Selected Industries */}
                      <div className="col-xl-6 col-lg-6">
                        {formData.industries.length > 0 && (
                          <div>
                            <h4>Selected Industries</h4>
                            {formData.industries.map((industry, index) => (
                              <div
                                key={index}
                                style={{
                                  borderRadius: "10px",
                                  border: "1px solid blue",
                                  padding: "5px 10px",
                                  margin: "5px",
                                  display: "inline-block",
                                }}
                              >
                                {industry}
                                <Badge
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => {
                                    removeIndustry(industry);
                                  }}
                                >
                                  X
                                </Badge>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* Avoid Companies List */}
                      <div className="col-xl-6 col-lg-6">
                        {formData.avoidCompanies.length > 0 && (
                          <div>
                            <h4>Avoiding Companies List</h4>
                            {formData.avoidCompanies.map((company, index) => (
                              <div
                                key={index}
                                style={{
                                  borderRadius: "10px",
                                  border: "1px solid blue",
                                  padding: "5px 10px",
                                  margin: "5px",
                                  display: "inline-block",
                                }}
                              >
                                {company}
                                <Badge
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                  }}
                                  onClick={() => {
                                    removeAvoidCompany(company);
                                  }}
                                >
                                  X
                                </Badge>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <Label for="resume">Resume</Label>
                          <Input
                            type="file"
                            name="resume"
                            id="resume"
                            onChange={handleFileChange}
                          />
                          <a href={formData.resume} target="_blank">
                            Open Uploaded File
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <Label for="avatar">Avatar</Label>
                          <Input
                            type="file"
                            name="avatar"
                            id="avatar"
                            onChange={handleFileChange}
                          />
                          <a href={formData.avatar} target="_blank">
                            Open Uploaded File
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <Label for="professionalCert">
                            Professional Certificate
                          </Label>
                          <Input
                            type="file"
                            name="professionalCert"
                            id="professionalCert"
                            onChange={handleFileChange}
                          />
                          <a href={formData.professionalCert} target="_blank">
                            Open Uploaded File
                          </a>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6">
                        <div className="comment-form__input-box">
                          <Label for="educationCert">
                            Education Certificate
                          </Label>
                          <Input
                            type="file"
                            name="educationCert"
                            id="educationCert"
                            onChange={handleFileChange}
                          />
                          <a href={formData.educationCert} target="_blank">
                            Open Uploaded File
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 col-lg-12">
                        <div className="comment-form__input-box text-message-box">
                          <textarea
                            name="coverLetter"
                            placeholder="Write Your Cover Letter"
                            id="coverLetter"
                            value={formData.coverLetter}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className="comment-form__input-box text-message-box">
                          <textarea
                            placeholder="Additional Info"
                            name="additionalInfo"
                            id="additionalInfo"
                            value={formData.additionalInfo}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        <div className="comment-form__btn-box">
                          <button
                            type="submit"
                            className="thm-btn comment-form__btn"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="result"></div>
                </div>
              </div>
            </div>
          </section>
          {/* <!--Contact Page End--> */}

          {/* <!--CTA One Start --> */}
          <section className="cta-one cta-three">
            <div className="container">
              <div className="cta-one__inner">
                <div
                  className="cta-one__bg"
                  style={{
                    backgroundImage: `url(${CTAThreeBgShape})`,
                  }}
                ></div>
                <div className="cta-one__title-box">
                  <h3>
                    Here to <span>Help</span> Your Every Business Need.
                  </h3>
                  <p>
                    Spend less time doing HR tasks and{" "}
                    <span>focus on what matters.</span> Together we translate HR{" "}
                    <br /> into Business usefulness.
                  </p>
                </div>
                <div className="cta-one__btn-box">
                  <a href="/contact" className="cta-one__btn thm-btn">
                    GET YOUR FREE QUOTE NOW
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* <!--CTA One End --> */}

          {/* <!--Site Footer Start--> */}
          <footer className="site-footer site-footer-three site-footer-four">
            <div className="site-footer__shape-1 float-bob-y">
              <img src={SiteFooterFourShape1} alt="" />
            </div>
            <div className="site-footer__top">
              <div className="container">
                <div className="site-footer__top-inner">
                  <div className="row">
                    <div
                      className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                      data-wow-delay="100ms"
                    >
                      <div className="footer-widget__column footer-widget__about">
                        <div className="footer-widget__logo">
                          {/* <!-- <a href="/"><img src="assets/images/resources/footer-logo-4.png"
                                                alt=""></a> --> */}
                          <a href="/">
                            <h2 style={{ color: "white" }}>Talentsync</h2>
                          </a>
                        </div>
                        <p className="footer-widget__about-text">
                          Get Noticed by Employers: TalentSync Finds You the
                          Perfect Job
                        </p>
                        <div className="footer-widget__emergency-call">
                          <a href="mailto:info@talentsync.com">
                            info@talentsync.com
                          </a>
                        </div>
                        <div className="footer-widget__social">
                          <a href="#">
                            <span className="icon-facebook"></span>
                          </a>
                          <a href="#">
                            <span className="icon-instagram-1"></span>
                          </a>
                          <a href="#">
                            <span className="icon-tik-tok"></span>
                          </a>
                          <a href="#">
                            <span className="icon-youtube"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                      data-wow-delay="200ms"
                    >
                      <div className="footer-widget__column footer-widget__navigation">
                        <div className="footer-widget__title-box">
                          <h3 className="footer-widget__title">Navigation</h3>
                        </div>
                        <ul className="footer-widget__navigation-list list-unstyled">
                          <li>
                            <a href="/">Home</a>
                          </li>
                          <li>
                            <a href="/about">About Us</a>
                          </li>
                          <li>
                            <a href="/how-it-works">How It Works</a>
                          </li>
                          <li>
                            <a href="/pricing">Pricing</a>
                          </li>
                          <li>
                            <a href="/contact">Contact Us</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="site-footer__bottom">
              <div className="container">
                <div className="site-footer__bottom-inner">
                  <p className="site-footer__bottom-text">
                    <a>Copyright © 2024 Talentsync.Agency.</a> All Rights
                    Reserved
                  </p>
                </div>
              </div>
            </div>
          </footer>
          {/* <!--Site Footer End--> */}
        </div>

        <div className="mobile-nav__wrapper">
          <div className="mobile-nav__overlay mobile-nav__toggler"></div>
          {/* <!-- /.mobile-nav__overlay --> */}
          <div className="mobile-nav__content">
            <span className="mobile-nav__close mobile-nav__toggler">
              <i className="fa fa-times"></i>
            </span>

            <div className="logo-box">
              <a href="/" aria-label="logo image">
                <img src={Logo2} width="150" alt="" />
              </a>
            </div>
            {/* <!-- /.logo-box --> */}
            <div className="mobile-nav__container"></div>
            {/* <!-- /.mobile-nav__container --> */}

            <ul className="mobile-nav__contact list-unstyled">
              <li>
                <i className="fa fa-envelope"></i>
                <a href="mailto:needhelp@packageName__.com">
                  needhelp@sonchoy.com
                </a>
              </li>
              <li>
                <i className="fa fa-phone-alt"></i>
                <a href="tel:666-888-0000">666 888 0000</a>
              </li>
            </ul>
            <div className="mobile-nav__top">
              <div className="mobile-nav__social">
                <a href="#" className="fab fa-twitter"></a>
                <a href="#" className="fab fa-facebook-square"></a>
                <a href="#" className="fab fa-pinterest-p"></a>
                <a href="#" className="fab fa-instagram"></a>
              </div>
            </div>
          </div>
          {/* <!-- /.mobile-nav__content --> */}
        </div>
        {/* <!-- /.mobile-nav__wrapper --> */}

        <div className="search-popup">
          <div className="search-popup__overlay search-toggler"></div>
          {/* <!-- /.search-popup__overlay --> */}
          <div className="search-popup__content">
            <form action="#">
              <label for="search" className="sr-only">
                search here
              </label>
              <input type="text" id="search" placeholder="Search Here..." />
              <button
                type="submit"
                aria-label="search submit"
                className="thm-btn"
              >
                <i className="fas fa-search"></i>
              </button>
            </form>
          </div>
          {/* <!-- /.search-popup__content --> */}
        </div>
        {/* <!-- /.search-popup --> */}

        <a
          href="#"
          data-target="html"
          className="scroll-to-target scroll-to-top"
        >
          <i className="fas fa-arrow-up"></i>
        </a>
      </div>
    </div>
  );
};

export default SubscriptionForm;
